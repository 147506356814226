import React, { useMemo } from 'react';
import type { FC, ReactNode } from 'react';
import styled from 'styled-components';
import { mobile, tablet } from 'styles/breakpoints';
import LogoIcon from 'assets/images/lastingChange/LastingChange_mob.png';
import LogoIconWhite from 'assets/icons/WhiteLogo.svg';
import BackButton from './BackButton';
import ProgressCount from './ProgressCount';
import { Color } from 'utils/theme';
import { DynamicImage } from 'components/images/DynamicImage';

type LogoVariant = 'left' | 'center';

interface HeaderProps {
  countTotal?: number;
  count?: number;
  hideCount?: boolean;
  onBack?: () => void;
  logoVariant?: LogoVariant;
  RightComponent?: ReactNode;
  sticky?: boolean;
  color?: Color;
  isWhite?: boolean;
}

export const HEADER_HEIGHT = 5;
export const HEADER_HEIGHT_MOBILE = 4.25;

const Wrap = styled.div`
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 999;
`;

const Container = styled.div<{ color?: Color }>`
  height: ${HEADER_HEIGHT}rem;
  display: flex;
  justify-content: center;
  width: 100%;
  background-color: ${({ theme, color }) =>
    theme.colors[color ? color : 'light0']};

  @media ${mobile} {
    width: 100%;
    overflow-x: hidden;
    height: ${HEADER_HEIGHT_MOBILE}rem;
  }
`;

const LogoIconStyledWhite = styled(LogoIconWhite)`
  width: 6.78rem;
  @media ${tablet} {
    width: 5.55rem;
    z-index: 999;
  }
`;
const LogoIconStyled = styled(DynamicImage)<{ color?: Color }>`
  height: 24px;
  background-color: ${({ theme, color }) =>
    theme.colors[color ? color : 'light0']};
  @media ${tablet} {
    max-height: 20px;
    z-index: 999;
  }
`;

const ContentContainer = styled.div<{ logoVariant: LogoVariant }>`
  margin: 0 ${({ logoVariant }) => (logoVariant === 'center' ? 3 : 1)}rem;
  display: flex;
  flex: 1;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  max-width: ${({ logoVariant }) =>
    logoVariant === 'left' ? '56.25rem' : 'none'};

  @media ${tablet} {
    margin: 0 1rem;
  }
`;

const Section = styled.div<{ justifyContent?: 'left' | 'center' | 'right' }>`
  display: flex;
  width: 182px;
  justify-content: ${({ justifyContent }) =>
    justifyContent ? justifyContent : 'center'};
  @media ${mobile} {
    min-width: 182px;
    flex: unset;
    min-width: 0;
    width: 144px;
  }
`;

const Gap = styled.div`
  width: 100%;
  height: ${HEADER_HEIGHT}rem;

  @media ${mobile} {
    height: ${HEADER_HEIGHT_MOBILE}rem;
  }
`;

const Header: FC<HeaderProps> = ({
  logoVariant = 'center',
  countTotal,
  count,
  hideCount,
  onBack,
  RightComponent,
  sticky = true,
  color,
  isWhite,
}) => {
  const visibleBackButton = useMemo(
    () => logoVariant === 'center',
    [logoVariant],
  );

  const renderRightSectionContent = () => {
    if (logoVariant === 'left') {
      return RightComponent;
    }
    return (
      typeof countTotal === 'number' &&
      typeof count === 'number' &&
      !hideCount && <ProgressCount total={countTotal} count={count} />
    );
  };

  return (
    <>
      {sticky ? (
        <>
          <Wrap>
            <Container color={color}>
              <ContentContainer logoVariant={logoVariant}>
                {visibleBackButton && (
                  <Section justifyContent="left">
                    {onBack && <BackButton onClick={onBack} />}
                  </Section>
                )}
                <Section justifyContent={logoVariant}>
                  {isWhite ? (
                    <LogoIconStyledWhite />
                  ) : (
                    <LogoIconStyled
                      src="lastingChange/LastingChange_mob.png"
                      alt="logo"
                    />
                  )}
                </Section>
                <Section color={color} justifyContent="right">
                  {renderRightSectionContent()}
                </Section>
              </ContentContainer>
            </Container>
          </Wrap>
          <Gap />
        </>
      ) : (
        <Container color={color}>
          <ContentContainer logoVariant={logoVariant}>
            {visibleBackButton && (
              <Section justifyContent="left">
                {onBack && <BackButton onClick={onBack} />}
              </Section>
            )}
            <Section color={color} justifyContent={logoVariant}>
              {isWhite ? (
                <LogoIconStyledWhite />
              ) : (
                <LogoIconStyled
                  color={color}
                  src="lastingChange/LastingChange_mob.png"
                  alt="logo"
                />
              )}
            </Section>
            <Section justifyContent="right">
              {renderRightSectionContent()}
            </Section>
          </ContentContainer>
        </Container>
      )}
    </>
  );
};

export default React.memo(Header);
