import React, { FC, useMemo } from 'react';
import styled from 'styled-components';
import { mobile, tablet, useQuery } from 'styles/breakpoints';
import { Text } from 'components';
import ReactCountdown from 'react-countdown';

interface HeaderCountdownProps {
  isBf?: boolean;
  isSticky?: boolean;
}

export const HeaderCountdown: FC<HeaderCountdownProps> = ({
  isBf,
  isSticky,
}) => {
  const countdownDate = useMemo(() => Date.now() + 900000, []);
  const { isMobile } = useQuery();
  const renderColon = () => (
    <Colon type={isMobile ? 'h4' : 'countS'} isBf={isBf}>
      :
    </Colon>
  );

  const renderer = ({
    formatted = {
      days: '00',
      hours: '00',
      minutes: '00',
      seconds: '00',
    },
  }) => (
    <ReactCountdownContainer>
      {!isBf && (
        <>
          <CountdownUnitsContainer>
            <Time>{formatted.hours}</Time>
          </CountdownUnitsContainer>
          {renderColon()}
        </>
      )}
      <CountdownUnitsContainer>
        {isBf ? (
          <BFTime isBf={isBf}>{formatted.minutes}</BFTime>
        ) : (
          <Time isBf={isBf}>{formatted.minutes}</Time>
        )}
      </CountdownUnitsContainer>
      {renderColon()}
      <CountdownUnitsContainer>
        {isBf ? (
          <BFTime isBf={isBf}>{formatted.seconds}</BFTime>
        ) : (
          <Time isBf={isBf}>{formatted.seconds}</Time>
        )}
      </CountdownUnitsContainer>
    </ReactCountdownContainer>
  );

  if (isBf) {
    return (
      <BFHeaderCountdown isSticky={isSticky}>
        <BFWrapper>
          <BFCountdownTitle>
            <b>Christmas offer</b> expires soon!
          </BFCountdownTitle>
          <ReactCountdown date={countdownDate} renderer={renderer} />
        </BFWrapper>
      </BFHeaderCountdown>
    );
  }

  return (
    <HeaderCountDown>
      <CountdownTitle>Limited price for</CountdownTitle>
      <ReactCountdown date={countdownDate} renderer={renderer} />
    </HeaderCountDown>
  );
};

const HeaderCountDown = styled.div``;

const BFHeaderCountdown = styled.div<{ isSticky?: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;
  background: #a4202f;
  padding: 0.625rem 0;
  position: ${({ isSticky }) => (isSticky ? 'sticky' : 'static')};
  top: 0;
  z-index: 99;

  @media ${tablet} {
    padding: 1rem;
  }
`;

const ReactCountdownContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
`;

const CountdownUnitsContainer = styled.div<{
  hasMarginLeft?: boolean;
}>`
  flex-direction: column;
  align-items: center;
  text-align: center;
  font-weight: 700;
  font-family: 'Open Sans';
  margin-left: ${({ hasMarginLeft }) => (hasMarginLeft ? '0.625rem' : '0')};

  color: red;
`;

const Time = styled(Text)<{ isBf?: boolean }>`
  text-align: center;
  font-size: 1.375rem;
  font-style: normal;
  ${({ isBf }) => (isBf ? 'color: #FFFFFF;' : 'color: #FF875A;')}
  font-weight: 700;

  @media ${tablet} {
    /* font-size: 1.625rem; */
    ${({ isBf }) => isBf && 'font-size: 1.25rem;'}
  }

  line-height: 130%;
`;

const BFTime = styled(Time)`
  color: #fff;
  font-family: 'Open Sans', sans-serif;
  font-size: 1.75rem;
  font-style: normal;
  font-weight: 700;
  line-height: 130%; /* 2.275rem */
  letter-spacing: 0.0105rem;

  @media ${tablet} {
    font-size: 1.25rem;
    letter-spacing: 0.0075rem;
  }
`;

const Colon = styled(Time)`
  /* margin: 0;
  font-weight: 700;
  ${({ isBf }) => (isBf ? 'color: #000;' : 'color: rgb(128, 77, 161);')} */
`;

const CountdownTitle = styled.p`
  color: #28293d;
  text-align: center;
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 700;
  line-height: 130%;
  letter-spacing: -0.015rem;
  text-transform: uppercase;
`;

const BFCountdownTitle = styled(CountdownTitle)`
  color: #fff;
  font-family: 'Open Sans', sans-serif;
  font-size: 1.25rem;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  letter-spacing: -0.0275rem;
  text-transform: none;

  b {
    font-weight: 700;
  }

  @media ${tablet} {
    font-size: 0.875rem;
  }
`;

const BFWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  max-width: 48rem;
`;
