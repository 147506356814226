import { floor } from 'lodash';
import React, { FC } from 'react';
import { useSelector } from 'react-redux';
import { AppState } from 'state/types';
import styled from 'styled-components';
import { Product } from 'types/product';
import { getLocalisedProduct } from 'utils/localization';

interface LoopingBannerProps {
  bannerTexts: string[];
  isNotSticky?: boolean;
  savings?: string;
  isBundle?: boolean;
  upsellData?: Product[];
  bundlePricing: () => any;
}

const LoopingBanner: FC<LoopingBannerProps> = ({
  bannerTexts,
  isNotSticky,
  savings,
  isBundle,
  upsellData,
  bundlePricing,
}) => {
  const products = useSelector(
    (state: AppState) => state.funnel.products,
  ) as Product[];

  const calculatePercentage = () => {
    const bookSavings = window.location.pathname.includes('checkout-special')
      ? '70'
      : '70';

    if (!isBundle) {
      return bookSavings;
    }

    if (isBundle && upsellData!.length > 0) {
      const { discount } = bundlePricing();
      return discount;
    }

    return '';
  };

  const currentPercentage = calculatePercentage();
  // const localisedProduct = getLocalisedProduct(bookSavings as Product);

  return (
    <Wrapper isNotSticky={isNotSticky}>
      <Container>
        {Array(50)
          .fill(null)
          .map((_, index) => (
            <BannerText
              key={index}
              isWhite={index % 2 === 0}
              dangerouslySetInnerHTML={{
                __html: bannerTexts[index % bannerTexts.length].replace(
                  '${percent}',
                  isBundle ? currentPercentage : savings || '70',
                ),
              }}
            />
          ))}
      </Container>
    </Wrapper>
  );
};

export default LoopingBanner;

const Wrapper = styled.div<{ isNotSticky?: boolean }>`
  width: 100%;
  overflow: hidden;
  white-space: nowrap;
  position: ${({ isNotSticky }) => (isNotSticky ? 'relative' : 'sticky')};
  top: 0;
  height: 2.625rem;
  display: flex;
  padding: 0.75rem 0rem;
  justify-content: center;
  align-items: center;
  gap: 2rem;
  z-index: 9;
  background: #a4202f;
`;

const Container = styled.div`
  display: inline-block;
  position: absolute;
  left: 0;
  will-change: transform;
  animation: scroll 90s linear infinite;
  padding-right: 100%;
  @media (prefers-reduced-motion: reduce) {
    animation: none;
  }

  @keyframes scroll {
    from {
      transform: translateX(0%);
    }
    to {
      transform: translateX(-50%);
    }
  }
`;

const BannerText = styled.span<{ isWhite: boolean }>`
  display: inline-flex;
  align-items: center;
  padding: 0 1rem;
  line-height: 1.1;
  color: #fff;

  span {
    background: radial-gradient(
      255.15% 74.63% at 71% 40%,
      #edce82 0%,
      #c7a753 100%
    );
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    color: transparent;
  }

  font-family: 'Open Sans', sans-serif;
  font-size: 1.0625rem;
  font-style: normal;
  font-weight: 700;
  line-height: 120%; /* 1.275rem */
  letter-spacing: -0.017rem;
`;
